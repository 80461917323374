import React from 'react'
import  './contact.scss'

export default function Contact({ mobile = false }) {
    return (
        <section className="contact">
            {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
            <h1 className="text-center text-white mt-2 mb-4">About</h1>
            <p className="text-center text-white m-4">NEWBO is an an on-demand video streaming platform for movies, web series contents made and originating from North Eastern regions of India. We are trying to create an unified entertainment platform for people in the region.</p>
            
        </section>
    )
}
