import React, { useEffect, useState } from "react";
import TransactionForm from './Payu/TransactionRequestForm'
import { createPayuOrder } from '../../api/apis';
import { useParams } from 'react-router-dom'
// import {Fetch} from "../../models/Item";
import { UseAuth } from '../../context/AuthContext'
// import { FetchSubscription } from "../../models/SubscriptionPlans";
import { FlapperSpinner } from "react-spinners-kit";

const PayuPage = () => {
    const {user} = UseAuth()
    const {id, type, name} = useParams()
    const [loading, setLoading] = useState(false)
    // const [item, setItem] = useState(null)
    const [order, setOrder] = useState(null)
    const [error, setError] = useState(null)
    const [orderPayload, setOrderPayload] = useState(null)
    
    useEffect(() => {  if(user!==null && user.uid)  getItem() }, [user])

    const getItem = async () => {
        setLoading(true)
        try {
            // if(type!=='subscription') {
            //     let temp = await Fetch(id)
            //     setItem(temp.data())
            // } else {
            //     let temp = await FetchSubscription({id})
            //     setItem({
            //         ...temp.data(), 
            //         visible_price: (parseInt(temp.data().actual_price)/100).toFixed(2),
            //         svisible_price: (parseInt(temp.data().offer_price)/100).toFixed(2)
            //     })
            // }
            setOrderPayload({
                item_id: id,
                user_id: user.uid,
                item_type: type,
                name: user.displayName || 'Guest User',
                phone_number: user.phoneNumber.slice(-10),
                email: user.email || "newboindia@gmail.com",
                item_name: name, 
                udf1: user.uid,
                udf2: '', 
                udf3: '', 
                udf4: '', 
                udf5: '' 
            })
            
            setError(null)
        } catch (error) {
            setError(error.message)
            console.log(error)
        }
        // setLoading(false)
    }

    useEffect(() => {
        if(orderPayload) { callCreateOrder() }
    }, [orderPayload])

    const callCreateOrder = async () => {
        setLoading(true)
        try {
            setOrder(await (await createPayuOrder(orderPayload)).json())
            setError('')
        } catch (error) {
            setError(error.message)
        }
        setLoading(false)
    }

    return (
        <div style={{minHeight: '50vh'}}>
            {loading && <Loading message={'Generating order. Please wait...'}/>}
            {error && <p>{error}</p>}
            {user && order && <TransactionForm order={{ ...orderPayload, ...order }}/>}
        </div>
    )
}

const Loading = ({message = 'Loading please wait....'}) => {
    return <div className="text-center text-white" style={{minHeight: 300}}>
    <div style={{width: 60, height: 60, margin: '50px auto'}}><FlapperSpinner size={60}/></div>
    <h3 >{message}</h3>
</div>
}

export default PayuPage