import React from 'react'
import  './contact.scss'

export default function Contact({ mobile = false }) {
    return (
        <section className="contact">
            {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
            <h1 className="text-center text-whiter mt-2 mb-4">Contact</h1>
            <p className="text-center text-whiter m-4">For any kind of query or if you have content to publish, you can reach us at following -</p>
            <div className="cards">
                <div className="card ">
                    <h1>📍</h1>
                    <h2>Office Address</h2>
                    <address>Ward No. 10 Kokrajhar BTR Assam</address>
                </div>
                <div className="card shadow">
                    <h1>📞</h1>
                    <h2>Call us </h2>
                    <p className="mb-0">+ 91 7002422992</p>
                    <p>+ 91 7636075620</p>
                </div>
                <div className="card">
                    <h1>✉️</h1>
                    <h2>Email us</h2>
                    <p className="mb-0">newboindia@gmail.com</p>
                </div>
            </div>
        </section>
    )
}
