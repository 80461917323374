import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import './hero.scss'
import {FaPlayCircle} from 'react-icons/fa'
import RelatedCarousel from './RelatedCarousel'
import { FlapperSpinner } from 'react-spinners-kit'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {arrayToUpperCase, dashToUppercase} from '../../utils/StringManipulation'
import Plyr from 'plyr'
import Hls from 'hls.js';
import {
    FacebookShareButton,
    WhatsappShareButton,
    FacebookMessengerIcon,
    WhatsappIcon
  } from "react-share";

import { Fetch, FetchWatchUrls, CheckPurchase } from '../../models/Item'
import { UseAuth } from '../../context/AuthContext'
import { useRef } from 'react'
import Swal from 'sweetalert2'
import { createOrder } from '../../api/apis';
import firebase from 'firebase'
import 'firebase/auth'

import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';
import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';

export default function Home() {
    const {user} = UseAuth()
    const playerRef = useRef(null)
    // const playerRefDummy = useRef(null)
    
    const {type, itemId} = useParams()
    const [item, setItem] = useState(null)
    const [loading, setLoading] = useState(false)
    // const [player, setPlayer] = useState(null)
    const [watchUrl, setWatchUrl] = useState(null)
    let history = useHistory()
    let [isPurchaseChecked, setIsPurchaseChecked] = useState(false)
    let [purchaseStatus, setPurchaseStatus] = useState(null)
    // let [hls, setHls] = useState(null)


    const [showTrailer, setShowTrailer] = useState('')

    useEffect(() => {
        setLoading(true)
        Fetch({id: itemId})
        .then((snap) => {
            if(snap.exists){
                let data = snap.data()
                setItem(data)
                if(data.mobile_only) return 
                if(data.availability==='rent' && firebase.auth().currentUser){
                    firebase.auth().currentUser.getIdToken(true).then((token) => {
                        CheckPurchase(token, itemId)
                        .then(async (resp) => {
                            let data = await resp.json()
                            // disable play at the moment
                            // setPurchaseStatus(data)
                            // if(data.active) initPlayer(data)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => {
                            setIsPurchaseChecked(true)
                        })
                    })
                } else if(data.availability==='subscription'){
                    if(!user.subscription){
                        return 
                    }
                }
                 else {
                   initPlayer(data)
                }
            }
        })
        .finally(() =>{
            setLoading(false)
        })
    }, [itemId])

    

    const initPlayer = async (item) => {
        
        // let tplayerDummy = new Plyr(playerRef.current, defaultOptions)

        let onceFunction = async () => {
            if(!user) {
                return Swal.fire({
                    title: 'Please Log in to Watch',
                    icon: 'info',
                    confirmButtonText: 'Login',
                })
                .then((resp) => {
                    if(resp.isConfirmed) {
                        history.push('/signin')
                    }
                })
            }
            // if(item.mobile_only) return Swal.fire({
            //     title: 'Sorry this is available only on app currently!',
            //     text: 'For any help contact us!',
            //     confirmButtonText: 'Close'
            // })

            if(item && item.availability==='rent' ){
                if(!isPurchaseChecked) {
                    
                     Swal.fire({
                        title: 'Please wait.. checking license',
                        showLoaderOnConfirm: true,
                        showConfirmButton: false,
                        preConfirm: () => {
                            return new Promise((resolve) => {
                                setTimeout(() => {resolve(2)}, 2000)
                            })
                        }
                    })

                    setTimeout(() => {
                        Swal.close()
                    }, 2000)

                    return
                }
                if(purchaseStatus && !purchaseStatus.active){
                    return Swal.fire({
                        title: 'Please rent to watch the content!',
                        showCancelButton: true,
                        showConfirmButton: true,
                        preConfirm: () => {
                            return initRent()
                        }
                    })
                }
            } else {
                // PLAY DISBALED FOR NOW
                const snap = await FetchWatchUrls(itemId)
                let snapData = await snap.data()
                setWatchUrl(snapData)
            }
          
            
        }
        // if(tplayerDummy && tplayerDummy.elements.container) {
        //     tplayerDummy.elements.container.addEventListener('click', onceFunction)
            
        // }
        // onceFunction()
        // console.log(playerRef.current)

        if(!watchUrl){
            onceFunction()
        }
    }

    // const initHlsPlay = (snapData) => {
    //     const defaultOptions = { settings: ['quality', 'speed'] };

    //     if (snapData && snapData.url_hls && Hls.isSupported()) {
    //         // playerRef.current.innerHTML = `<source src="${snapData.url_hls}" type="application/x-mpegURL"></source>`
    //         console.log('ascsacsa', snapData)
    //          let _hls = new Hls()
    //         setHls(_hls);
    //         _hls.loadSource(snapData.url_hls);
    //         _hls.on(Hls.Events.MANIFEST_PARSED,function() {
    //             const availableQualities = _hls.levels.map((l) => l.height)
    //             defaultOptions.quality = {
    //                 default: availableQualities[0],
    //                 options: availableQualities,
    //                 // this ensures Plyr to use Hls to update quality level
    //                 forced: true,        
    //                 onChange: (e) => updateQuality(e),
    //             }
           
    //             let tplayer2 = new Plyr(playerRef.current, defaultOptions)
    //             // setPlayer(tplayer2)
               
    //             // playerRef.current.play();
    //         });
    //         _hls.attachMedia(playerRef.current);
    //     }
    // }

    // function updateQuality(newQuality) {
    //     hls.levels.forEach((level, levelIndex) => {
    //       if (level.height === newQuality) {
    //         console.log("Found quality match with " + newQuality);
    //         hls.currentLevel = levelIndex;
    //       }
    //     });
    //   }

    const playTrailerButton = () => {
        // switch (item.availability){
        //     case 'subscription':{
        //         if(!user.active){
        //             return (
        //                 <button className="btn btn-outline-success text-uppercase" onClick={notify} style={{marginRight: 15}}> <FaPlayCircle/> Subscribe To Watch</button>
        //             )
        //         }
        //         break
        //     }
        //     case 'rent':{
        //         return (
        //             <button className="btn btn-outline-success text-uppercase" onClick={notify} style={{marginRight: 15}}> <FaPlayCircle/> Rent To Watch</button>
        //         )
        //     }
        //     case 'buy':{
        //         return (
        //             <button className="btn btn-outline-success text-uppercase" onClick={notify} style={{marginRight: 15}}> <FaPlayCircle/> Purchase To Watch</button>
        //         )
        //     }
        //     default: {
        //         return (<button className="btn btn-outline-success text-uppercase" onClick={notify} style={{marginRight: 15}}> <FaPlayCircle/> Watch Now</button>)
        //     }
        // }
        return <a className="btn btn-outline-success text-uppercase" data-bs-toggle="modal" href="#trailerModal" onClick={playTrailer} style={{marginRight: 15}}> <FaPlayCircle/> Play Trailer</a>
    }

    const playTrailer = () => {
        if(!item.trailer_hls) return Swal.fire({
            title: 'No Trailer Available.',
            icon: 'info',
            showConfirmButton: false,
            timer: 2000
        })
        setShowTrailer('show')
    }

    const initRent = () => {
        return history.push(`/payment-payu/${itemId}/rent/${item.title}`)
        // Swal.fire({
        //     title: 'Initiating order please wait....',
        //     showLoaderOnConfirm: true,
        //     allowOutsideClick: false,
        //     allowEscapeKey: false,
        //     preConfirm: () => {
        //         return createOrder({
        //                 userID: user.uid,
        //                 itemID: item.id,
        //                 type: 'rent',
        //                 phoneNumber: user.phoneNumber,
        //                 itemName: item.name
        //             })
        //     }
        // })
        // .then(async (resp) => {
        //     if(resp.value && resp.value.status==200){
        //         let mOrder = await resp.value.json()
        //         history.push(`/payment?pi=${mOrder.paymentIntent.client_secret}&r=${window.location.href}`)
        //         // initRazorpay(mOrder)
        //     }
        // })
        // .catch(error => {
        //     console.log(error)
        //     Swal.fire({
        //         title: 'Payment Failed',
        //         icon: 'error',
        //         text: error.message
        //     })
        // })
        // Swal.clickConfirm()
    }

    const renderRentButton = () => {
        return ''
        if(item && item.availability==='rent'){
            return <button className="btn btn-secondary m-1">Currently playable only on android app</button>
            if(purchaseStatus && purchaseStatus.active) return <></>
            return <button className="btn btn-primary" style={{marginRight: 15}} disabled={!isPurchaseChecked} onClick={() => initRent()}>RENT for {(item.amount_rent/100).toFixed(2)} Rs.</button>
        } else return <></>
    }

    const renderSubscribeButton = () => {
        if(item && item.availability==='subscription' && !user.subscription){
            return <button style={{marginRight: 15}} className="btn btn-primary mr-2"  onClick={() => history.push('/subscription')}>Subscribe to watch</button>
        } else return <></>
    }

    const playEpisode = (ep) => {
        if(item.mobile_only) {
            return Swal.fire({
                    title: 'Sorry this is available only on app currently!',
                    text: 'For any help contact us!',
                    confirmButtonText: 'Close'
                })
        }
        if(item.availability==='subscription' && !user.subscription){
            return Swal.fire({
                title: 'Please subscribe to watch it!',
                confirmButtonText: 'Subscribe',
                preConfirm: () => {
                    return history.push('/subscription')
                }
            })
        } else {
            window.scrollTo(0,0)
            setWatchUrl({url_hls: ep.watch_hls})
        }
        // initHlsPlay({url_hls: ep.watch_hls})
    }

      
    return (
        <div className="container-fluid p-4 pt-2">
            <div aria-label="breadcrumb" style={{ color: 'white', marginTop: 20, marginBottom: 20, padding: 0 }}>
                <ol className="breadcrumb" style={{padding: 5, borderRadius: 2}}>
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to={`/${type}`}>{dashToUppercase(type)}</Link></li>
                    {item?<li className="breadcrumb-item active" aria-current="page">{item.title.charAt(0).toUpperCase()+item.title.substring(1)}</li>:<></>}
                </ol>
            </div>
            {loading?<div className="text-center text-white" style={{minHeight: 300}}>
                <div style={{width: 60, height: 60, margin: '50px auto'}}><FlapperSpinner size={60}/></div>
                <h3 >Loading...</h3>
            </div>:<></>}
            {item?<>
                <div className="hero-image" style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url('${item.thumb_big}')`, 
                            backgroundSize: 'cover', 
                            width: '100%',
                            height: '100%'}}>
                
                <div className="hero-text" style={{top: 0, left: 0, transform: 'none', textAlign: 'left', color: 'white'}}>
                    <div className="row single-view">
                        <div className="col-md-7">
                            <h3 className="mt-0">{item.title}</h3>
                            {/* <p>{[1,2,3,4,5].map((s) => <FaStar className="mr-1 text-warning" key={s}/>)}</p> */}
                            <p style={{textTransform: 'capitalize', padding: 4, border: '1px solid #fff', display: 'inline-block', borderRadius: 4}}>{item.availability}</p>
                            <h5 className="cast"><i>Casts : </i> {item.casts.length?arrayToUpperCase(item.casts):'NA'}</h5>
                            <h5 className="genre"><i>Genre : </i>  {item.genres.length?arrayToUpperCase(item.genres):'NA'}</h5>
                            <h5 className="director"><i>Director : </i>  {item.director || 'NA'}</h5>
                            <h5 className="duration"><i>Duration : </i>  {item.duration} mins</h5>
                            <p>{item.short_description}</p>
                            {playTrailerButton()} {renderRentButton()} {renderSubscribeButton()}
                            {item.mobile_only?<p><i style={{color: 'red'}}>Currently this is available exclusive only on <a href="https://play.google.com/store/apps/details?id=com.mars.newatch" target="_blank" rel="noreferrer">app</a>*</i></p>:''}
                            {/* <button className="btn btn-outline-primary m-2 text-uppercase"><FaPlus /> Add to Wishlist</button> */}
                            {/* <button className="btn btn-outline-info m-2 text-uppercase"><FaShare /> Share</button> */}
                            <FacebookShareButton quote={'Watch this!'} style={{marginRight: 15}} url={`${window.location.origin}/${type}/${itemId}`}><FacebookMessengerIcon size={35} borderRadius={0}/></FacebookShareButton>
                            <WhatsappShareButton title="Watch this!" url={`${window.location.origin}/${type}/${itemId}`}><WhatsappIcon size={35}  borderRadius={0}/> </WhatsappShareButton>
                        </div>
                        <div className="col-md-5 text-center">
                            {/* <video id="plyr" ref={playerRef} controls playsInline poster={item.thumb_big} >
                            </video> */}
                            {/* <video id="plyr" ref={playerRef} controls playsInline poster={item.thumb_big} onPlay={initPlayer}>
                                {watchUrl && watchUrl.url_hls?<source src={watchUrl.url_hls} type={'application/x-mpegURL'}></source>:<></>}
                            </video> */}
                            {!watchUrl || !watchUrl.url_hls?<video id="plyr" ref={playerRef} playsInline poster={item.thumb_big} onPlay={initPlayer} style={{maxWidth: '100%'}}></video>:
                            <Replay
                                source={watchUrl.url_hls}
                                options={{
                                    controls: {
                                        includeControls: [
                                            'playPauseButton',
                                            'timeline',
                                            'timeDisplay',
                                            'volume',
                                            'fullscreenButton',
                                            'qualitySelector',
                                            'bufferingIndicator'
                                        ]
                                    }
                                }}
                                >
                                    <HlsjsVideoStreamer />
                            </Replay>}
                        </div>
                    </div>
                    <div className='alert alert-info text-center'>Currently premium movies are available only on android app! <b><a href="https://cdn62706266.blazingcdn.net/newbo/app-release.apk" target="_blank">Download android app here.</a></b> </div>
                    <div className="col-md-12 mt-4" style={{maxWidth: 500}}>
                        <div className="list-group" style={{background: "aliceblue"}}>
                        {item && item.episodes && item.episodes.filter(cv => cv.active).map((cv, index) => <div key={index} className="list-group-item"><i><small>Episode 01</small></i> - <b>{cv.title}</b> <button className="btn btn-outline-primary pull-right" onClick={() => playEpisode(cv)}>Play</button></div>)}
                        </div>
                    </div>
                </div>
            </div>
            </>:<></>}
            
            <Trailer item={item} showTrailer={showTrailer} setShowTrailer={setShowTrailer}/>
            <ToastContainer />
            <RelatedCarousel type={type} />
            
        </div>
    )
}




const Trailer = ({item, showTrailer = '', setShowTrailer})  => {
    const playerRefTrailer = useRef(null)
    const [playerTrailer, setPlayerTrailer] = useState(null)
    
    useEffect(() => {
        if(showTrailer === 'show') initTrailerPlayer(item)
    }, [showTrailer])

    const initTrailerPlayer = (item) => {
        if (Hls.isSupported() && item.trailer_hls) {
            var hls = new Hls();
            hls.loadSource(item.trailer_hls);
            hls.attachMedia(playerRefTrailer.current);
            hls.on(Hls.Events.MANIFEST_PARSED,function() {
                // playerRefTrailer.current.play();
            });
        }
        let tplayer = new Plyr(playerRefTrailer.current)
        // tplayer.setup(playerRef.current)
        setPlayerTrailer(tplayer)
        // setPlayer(tplayer)
    }

    if(!item || !showTrailer) return <></>

   

    const closeTrailer = () => {
        setShowTrailer('')
        if(playerTrailer) {
            playerTrailer.stop()
        }
    }


    
    return (
        <div className={`modal fade ${showTrailer}`} id="trailerModal" aria-hidden="true" aria-labelledby="trailerModalLabel" tabindex="-1">
        <div className="modal-dialog modal-dialog-centered" style={{minWidth: '500px', maxWidth: '70%'}}>
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="trailerModalLabel">Trailer</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeTrailer}></button>
            </div>
            <div className="modal-body">
                {item? <video id="plyr2" ref={playerRefTrailer} controls crossOrigin playsInline poster={item.thumb_big}>
                    {item.trailer_hls?<source src={item.trailer_hls} type="application/x-mpegURL"  />:<></>}
                </video>: <></>}
            </div>
            
            </div>
        </div>
        </div>
    )
}