import firebase from 'firebase/app'
import 'firebase/auth'

var config = {
    apiKey: "AIzaSyCisG8oAsB9B7Dfk6vuCLBeZkBvtVNjOLM",
    authDomain: "newatch-6c7c7.firebaseapp.com",
    databaseURL: "https://newatch-6c7c7.firebaseio.com",
    projectId: "newatch-6c7c7",
    storageBucket: "newatch-6c7c7.appspot.com",
    messagingSenderId: "362662393261",
    appId: "1:362662393261:web:c9582be5b817ce05e11d24",
    measurementId: "G-932YE1W2NY"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config)
}

export default () => null

let auth = firebase.auth()

export { auth }