import MovieLanding from "../views/Home/MovieLanding"
import { useEffect, useState } from "react"
//import { init } from "../Pixel"
//import ReactPixel from 'react-facebook-pixel';

const data = {
    '2rxLIeODwyTMwfhapaLb' : {
        name: 'JANGKHRITHAINI TWISAM',
        image: 'https://cdn62706266.blazingcdn.net/newbo/Movies_Api/JANGKRITAINI%20TWISAM%20TRAILER/jangkritainewbo-min.jpg'
    },
    'q655Bx4NRZKZm4dcYluP' : {
        name: 'Fwiला MCLA',
        image: 'https://s3.eu-central-1.wasabisys.com/newbo/Movies_Api/FMCLATrailer23/FWILA WEB POSTER.png'
    },
    'VFX5QGtMjHCGWAFZrRzV' : {
        name: 'MWSA',
        image: 'https://cdn62706266.blazingcdn.net/newbo/posters/mwsa.png'
    },
    '0e8p4pe10hN6HLcjEW7E' : {
        name: 'KHNAGKRAI',
        release: new Date(2024, 2, 29),
        image: 'https://s3.eu-central-1.wasabisys.com/newbo/Movies_Api/KHANGKRAI_TRAILER/KHANGKRAI ORIGINAL Compressed.jpeg'
    }
}

export default () => {
    
    const [movie, setMovie] = useState()
    
    useEffect(() => {
        
        let searchParams = (new URL(window.location.href)).searchParams
        let id = searchParams.get('id')
        //let pixelID = searchParams.get('pixel-id')

        let _movie = data[id]

        setMovie({
            id, ..._movie
        })

        //init(pixelID)
        
        //ReactPixel.pageView();

    }, [])
    
    //console.log(par, id, name, new URLSearchParams())

    if(!movie) return (<div style={{minHeight: 400}} className="text-center text-lg mt-4">404: Movie not found!</div>)
    return (<div>
        <MovieLanding image={movie.image} id={movie.id} release={movie.release} name={movie.name}/>
    </div>)
}