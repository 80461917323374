import React, { useEffect, useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import useResponsiveFontSize from "./useResponsiveFontSize";
import Swal from "sweetalert2";
import { useHistory } from "react-router";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const history = useHistory()
  const [queries, setQueries] = useState({})

  useEffect(() => {
    let temp = {}
    history.location.search.substring(1).split('&')
    .forEach( sq => { 
      temp[sq.split('=')[0]] = sq.split('=')[1] 
    })
    setQueries(temp)
  }, [])

  useEffect(() => {
    console.log(queries)
  }, [queries])

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    Swal.fire({
      title: 'Preparing order...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement)
        });
      }
    })
    .then((response) => {
      const paymentMethod = response.value
      if(paymentMethod && paymentMethod.error){
     
        if(paymentMethod.error.decline_code== "card_not_supported"){
          Swal.fire({
            title: 'Error!',
            icon: 'error',
            text: paymentMethod.error.message +' Please try with another card!'
          })
        } else {
          Swal.fire({
            title: 'Error!',
            icon: 'error',
            text: paymentMethod.error.message
          })
        }
      } else {
        console.log("[PaymentMethod]", paymentMethod);
        payWithCard(stripe, paymentMethod, queries.pi)
      }
    })
    Swal.clickConfirm()
  };

  // Calls stripe.confirmCardPayment
// If the card requires authentication Stripe shows a pop-up modal to
// prompt the user to enter authentication details without leaving your page.
var payWithCard = function(stripe, paymentMethod, clientSecret) {
  console.log(paymentMethod)
  Swal.fire({
    title: 'Processing payment...',
    text: 'Please wait.',
    allowOutsideClick: false,
    allowEscapeKey: false,
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethod.paymentMethod.id })
    }
  })
  .then((response) => {
    let result = response.value
      if (result.error) {
        // Show error to your customer
        showError(result.error.message);
      } else {
        // The payment succeeded!
        orderComplete(result.paymentIntent.id);
      }
  })
  Swal.clickConfirm()
};

/* ------- UI helpers ------- */

// Shows a success message when the payment is complete
var orderComplete = function(paymentIntentId) {
 console.log("https://dashboard.stripe.com/test/payments/" + paymentIntentId)
 Swal.fire({
   title: 'Success',
   icon: 'success',
   text: 'Payment completed successfully! It will be reflected shortly.',
   preConfirm: () => {
     if(queries.r){
       return setTimeout(() => {
         window.location.href = queries.r
       }, 500)
     }
     return history.replace('/')
   }
 })
};

// Show the customer the error from Stripe if their card fails to charge
var showError = function(errorMsgText) {
  console.log( errorMsgText )
  Swal.fire({
    title: 'Payment Failed',
    text: errorMsgText,
    icon: 'error'
  })
};


  return (
    <form onSubmit={handleSubmit} style={{width: '100%'}}>
      <label>
        Card number
        <CardNumberElement
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label>
      <label>
        Expiration date
        <CardExpiryElement
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label>
      <label>
        CVC
        <CardCvcElement
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label>
      <button type="submit" id="psubmit" disabled={!stripe}>
        PAY
      </button>
    </form>
  );
};

export default SplitForm;
