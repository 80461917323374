import firebase from 'firebase/app'
import 'firebase/firestore'

// const availabilities =['free', 'subscription', 'paid']
export default () => null

export const Fetch = ({id = '', type = '', limit = -1} = {}) => {
    let temp = firebase.firestore().collection('items')
    if(id) return temp.doc(id).get()
    temp = temp.where('active', '==', true)
    if(type ) temp = temp.where('type', '==', type)
    if(limit>-1) temp = temp.limit(limit)
    return temp.get() // .orderBy('created_at', 'dsc')
}

export const CheckOrder = async ( item, uid ) => {
    if(item.availability!=='paid') return 
    try {
        // console.log(item, uid)
        const snapshot = await firebase.firestore().collection('orders')
        .where('user_id', '==', uid)
        .where('item_id', '==', item.id)
        .where("status", '==', "paid")
        .get()
        let out = []
        snapshot.docs.forEach(v => {
            out.push({
                type: v['type'],
                expires_at: v['expires_at']
            })
        })
        return out
    } catch (error) {
        return []
    }
}

export const CheckPurchase = async (idToken, itemId) => {
    const CHECK_PURCHASE = 'https://us-central1-newatch-6c7c7.cloudfunctions.net/checkPurchase'
    return fetch(CHECK_PURCHASE, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, f
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            idToken,
            itemId
        }) 
    })
}

export const FetchWatchUrls = (id) => {
    return firebase.firestore().collection('watch').doc(id).get()
}