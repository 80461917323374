import React, { useEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel'
import { Link } from 'react-router-dom'
import { Fetch } from '../../models/Item'
import { qType } from '../../utils/qType'

function Func({ Data = [], type = 'movie', title = '' })  {
    const [breakPoints] = useState([
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 850, itemsToShow: 4, itemsToScroll: 2 },
      ])

      const [items, setItems] = useState([])
    //   const [loading, setLoading] = useState(false)

      let pType = qType(type)

      useEffect(() => {
        Fetch({type: pType})
        .then((snapshot) => {
            setItems(snapshot.docs.map((snap) =>  {return {...snap.data(), id: snap.id}}))
        })
        .finally(() =>{
            // setLoading(false)
        })
      }, [pType])

    
    return (
        <div className="mt-4 mb-4 pt-4 pb-4">
            <h1 className="h1-sm-carousel p-0">You may Also Like</h1>
            <Carousel breakPoints={breakPoints} renderPagination={() => <></>}>
               {items.map((movie, d) => (
                    <Link className="item-holder" to={`/${type}/${movie.id}`} key={d}>
                        <div className="item-bg" style={{ background: `url(${movie.thumb_big}) ` }} key={d}>
                            <div className="bottom">
                                <h5 style={{whiteSpace: 'nowrap'}}>{movie.title}</h5>
                                <p>{movie.duration} min - {(movie.genres || []).map(v => v.charAt(0).toUpperCase()+v.substring(1)).join(', ')}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </Carousel>
        </div>
    )
}


export default Func