import React, { useEffect, useState } from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import SplitForm from './SplitForm';
import './style.css'
const stripePromise = loadStripe('pk_test_51JErxxSCgcUaeqXjAla7aMCAGiUMrN8COX3lk8c4Iemi9tU5vb6UT6yn4qswBBGUd9vCy5FPVxmVRR4N6mZ3bTjy00N4NwF04X');

export default () => {

    return (
        <div className="DemoWrapper">
            <h3 style={{color: 'white', paddingBottom: 20}}>Card Payment</h3>
            <Elements stripe={stripePromise}>
                <SplitForm />
            </Elements>
        </div>
    )
}