import { createContext, useContext, useState, useEffect } from 'react'
import firebase from 'firebase/app';
import 'firebase/auth'
import { CheckSubscription } from '../api/apis';


export const PrimaryContext = createContext(null)
export const UseAuth = () => useContext(PrimaryContext)

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [checkedSubscription, setCheckedSubscription] = useState(null)

    useEffect(() => {
        const auth = firebase.auth()
        auth.onAuthStateChanged((user) => {
            setUser({...user})
            if(user && !checkedSubscription){
                setLoading(true)
                user.getIdToken(true).then((token) => {
                    CheckSubscription(token)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        setUser({...user, subscription: resp.active, subscription_data: resp.subscription })
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        setLoading(false)
                        console.log(loading)
                    })
                    setCheckedSubscription(true)
                })
                .catch((err) => {
                    console.log(err)
                })
                
            } else {
                // required dont hide
                setLoading(false)
            }
        })
    }, [])

    return (
        <PrimaryContext.Provider value={{user, setUser}}>
            {!loading && children}
        </PrimaryContext.Provider>
    )
}