import React, { useEffect, useState } from 'react'
import gplayDownload from './../../google-play-badge.png'

import ReactPixel from 'react-facebook-pixel'
import moment from 'moment'

export default function MovieLanding({id, name, release, image}) {

    const [formatDate, setFormatDate] = useState(null)
    const [isReleased, setIsReleased] = useState(false)

    useEffect(() => {
      if(release){
        let newMoment = moment(release)
        let todayMoment = moment()

        let releaseDate = moment().subtract(1,'day')
        console.log(releaseDate.isBefore(moment()))
        if(newMoment.isBefore(todayMoment)) {
          setIsReleased(true)
          // console.log( todayMoment.isAfter(newMoment) )
        }
        setFormatDate(newMoment.format('DD MMM \'YY'))
      }
    }, [release])

    return (
      <>
        <div className='d-flex' style={{minHeight: 600, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <img  src={image} style={{width: 480, maxWidth: '100%', padding: 5, borderRadius: 5, filter: 'drop-shadow(black 1px 4px 8px)'}}/>
          <h4 className='animate__animated animate__slideInUp title mt-4 text-center' style={{}}>{name}  {!isReleased && release?'will be streaming from '+formatDate:'Streaming Now'} on NEWBO</h4>
          <h4 className='animate__animated animate__slideInUp title mt-4 text-center' style={{}}>{name} खाै NEWBO app आव {!isReleased && release?formatDate+' निफृाय दिनतिनाय जागॊन':'दिनतिगासिनाे'}</h4>
          <div className='animate__animated animate__slideInUp '>
              <a href="https://play.google.com/store/apps/details?id=com.mars.newatch" target="_blank" rel="noreferrer">
                  <img style={{margin: '0 auto', maxHeight: 100}} className="nav-link download" src={gplayDownload} alt="Gplay" />
              </a>
          </div>
        </div>  
      </>
    )
}
