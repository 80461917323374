export const qType = (type) => {
    
    switch(type){
        case 'movies': {
            return 'movie'
        }
        case 'music-videos': {
            return 'music-video'
        }
        case 'web-series': {
            return 'series'
        }
        case 'tv-and-shows': {
            return 'tv-and-shows'
        }
        default: {
            return type
        }
    }
}