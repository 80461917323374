import React from 'react'
import {
    WhatsappShareButton,
    WhatsappIcon
  } from "react-share";
import { FaPhoneSquareAlt } from 'react-icons/fa';

const pub = () => {
    return (
        <div className="container text-whiter text-center p-4 d-flex justify-content-center flex-column" style={{minHeight:500}}>
            <h3 className="pt-4">Publish your Content</h3>
            <hr />
            <p className="mt-4">
                Producer or any Content Creators looking to publish and monetize their contents securely, can do so on our OTT platform. <br /> <br /> 
                NEWBO offers three type of contents - <b>(a) Free Content</b>, <b>(b) Subscription Based</b> and also <b>(c) Preimum Content</b> that are available to users on payment. <br /> <br /> 
                We work on either Revenue Sharing or One Time Payment model with content creator based on requirement.

                NEWBO the North east Wide Box office is an OTT platform created and managed from Assam with an ambition to build an ecosystem where young film makers from our region can experiment and at the same time exhibit their talents to the world. Like many other parts of the country our this region is too full of such raw talents. But hindered by the lack of opportunities and platforms our those talents are lagging behind from getting exposures.
                <br /> <br />
                <i>Contact us using following - </i>
                <br /> <br />
                <WhatsappShareButton title="Hi, I want to publish my content!! " url={`${window.location.origin}/publish`}><WhatsappIcon size={35}  borderRadius={0}/> </WhatsappShareButton>
                <a href="tel:+917002422992"><FaPhoneSquareAlt size={37} style={{marginLeft: 20, color: 'white'}}/> </a>
                <a href='mailto:newboindia@gmail.com'>newboindia@gmail.com</a>
            </p>
        </div>
    )
}

export default pub