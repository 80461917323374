import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import './movieList.scss'
import { Fetch } from '../../models/Item'
import { FlapperSpinner } from 'react-spinners-kit'
import { dashToUppercase } from '../../utils/StringManipulation'

export default function () {
    const {type, itemId} = useParams()
    // const [item, setItem] = useState(null)
    const [loading, setLoading] = useState(false)
    const [typeName] = useState(type.charAt(0).toUpperCase()+type.substring(1))
    const [items, setItems] = useState([])

    useEffect(() => {
        console.log(type)
        // setItem({
        //     casts: ['Gemsri'],
        //     genres: ['Action', 'Thriller']
        // })
        let queryParam = {}
        switch(type){
            case 'movies': {
                queryParam.type = 'movie'
                break;
            }
            case 'music-videos': {
                queryParam.type = 'music-video'
                break;
            }
            case 'web-series': {
                queryParam.type = 'series'
                break;
            }
            case 'tv-and-shows': {
                queryParam.type = 'tv-and-shows'
                break;
            }
        }
        setLoading(true)
        Fetch(queryParam).then((snapshot) => {
            setItems(snapshot.docs.map((snap) =>  {return {...snap.data(), id: snap.id}}))
        })
        .finally(() => {
            setLoading(false)
        })
        window.scrollTo(0,0)
    }, [])
    
    return (
        <div className="container-fluid mb-4" style={{minHeight: "60vh"}}>
            <div aria-label="breadcrumb">
                <ol className="breadcrumb m-4">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{dashToUppercase(typeName)}</li>
                </ol>
            </div>
            <div className="m-4">
                <h4 style={{color: 'white'}}>{dashToUppercase(typeName)}</h4>
            </div>
            {loading?<div className="text-center text-white" style={{minHeight: 300}}>
                <div style={{width: 60, height: 60, margin: '50px auto'}}><FlapperSpinner size={60}/></div>
                <h3 >Loading...</h3>
            </div>:<div className="movies-list" >
                {items.map((movie, d) => (
                    <div className="ml-item" key={d}>
                        <Link className="ml-mask jt" to={`/${type}/${movie.id}`}>
                            <span className="mli-cst">{movie.availability}</span>
                            <img data-original="" className="lazy thumb mli-thumb" alt={movie.thumb_big} src={movie.thumb_big} style={{display: 'block'}} />
                            <span className="mli-info"><h2>{movie.title} </h2></span>
                        </Link>
                    </div>
                ))}
                {items.length<1?<h3 className='text-center mt-4' style={{color: 'white'}}>Coming soon! Stay tuned.</h3>:<></>}
            </div>}
        </div>
    )
}
