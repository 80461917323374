import React from 'react'
import { PrimaryContext, UseAuth } from '../../context/AuthContext'
import {auth} from './../../initFirebase'
import { Link } from 'react-router-dom'

export default function Account() {
    return (
        <PrimaryContext.Consumer >
            {({user, setUser}) => (
                <div className="container shadow-bg text-white" style={{minHeight: 400}}>
                    <h1 className="text-center mt-5">My Profile</h1>
                    <div className="card pt-3 pb-3" style={{maxWidth: 400, margin: '0 auto', background: '#27334c'}}>
                        <div className="card-body">
                          
                            <i>Username</i>
                            <p>&nbsp;{user ? user.displayName : 'Please update username'} </p>
                            
                            <i>Phone Number</i>
                            <p>&nbsp;{user ? user.phoneNumber : 'Please update phone number'} </p>
                            <Link to="/signin"
                                onClick={async () => {await auth.signOut(); setUser(null)}}
                                className="btn btn-danger">Sign-out</Link>
                        </div>
                    </div>
                </div>
            )}
        
        </PrimaryContext.Consumer>
    );
}
