import React, { useEffect, useState } from "react";
import phoneHome from './../assets/home.jpeg'
import phoneMovie from './../assets/movie.jpeg'


export default () => {

    const [items, setItems] = useState([
        {
            image: phoneMovie,
            rotate: -5,
            marginLeft: 10,
            class: 'animate__fadeIn'
        },
        {
            image: phoneHome,
            rotate: 25,
            marginLeft: 180,
            class: 'animate__fadeIn animate__delay-1s'
        }
    ])
    useEffect(() => {
        if(window.innerWidth < 500){
            setItems(items.map(v => { 
                v.marginLeft = v.marginLeft/2 - 40; 
                v.rotate /= 2; 
                return v 
            }))
        }
      }, []);
    return (<div className="d-flex animate__animated animate__fadeInUp" style={{minHeight: '500px', justifyContent: 'center'}}>
        {items.map((item, index) => {
            return (<img src={item.image} className={'animate__animated ' +item.class} style={{width: 200, zIndex: index, marginLeft: item.marginLeft, marginTop: index*20, position: 'absolute', transform: `rotate(${item.rotate}deg)`}}/>)
        })}

    </div>)
}