import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { UseAuth } from './../context/AuthContext'
//import logo from './../app-logo.png'
import logo from './../assets/NewboWebsite.png'
import gplayDownload from './../google-play-badge.png'

export default function Navbar() {
    const user = UseAuth()
    const [showCollapse, setShowCollapse] = useState(false)

    return (
        <nav className="navbar navbar-expand-lg navbar-dark ">
            <div className="container-fluid">
                <Link className="brand" to="/"><img src={logo} style={{maxHeight: 80, filter: 'drop-shadow(1px 1px 1px black)', padding: 8}}/></Link>
                {/*<div className={`collapse navbar-collapse ${showCollapse?'show':''}`} id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" onClick={() => setShowCollapse(false)} to="/movies">MOVIES</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" onClick={() => setShowCollapse(false)} to="/web-series">WEB SERIES</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" onClick={() => setShowCollapse(false)} to="/tv-and-shows">TV & SHOWS</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" onClick={() => setShowCollapse(false)} to="/infotainment">INFOTAINMENT</NavLink>
                        </li>
                    </ul>
                </div>*/}
                <button className="navbar-toggler" type="button"
                    onClick={() => setShowCollapse(!showCollapse)}
                    data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className={`collapse navbar-collapse ${showCollapse?'show':''}`} id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" onClick={() => setShowCollapse(false)} to="/movies">MOVIES</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" onClick={() => setShowCollapse(false)} to="/web-series">WEB SERIES</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" onClick={() => setShowCollapse(false)} to="/tv-and-shows">TV & SHOWS</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" onClick={() => setShowCollapse(false)} to="/tv-and-shows">INFORMATION</NavLink>
                        </li> */}
                        {/*<li className="nav-item">
                            {user.user === null ? <NavLink className="btn btn-primary mr-2"
                                to="/signin">Sign In</NavLink>
                                : <NavLink className="btn btn-primary mr-2"
                                    to="/account">My Account</NavLink>
                            }
                        </li>*/}
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.mars.newatch" target="_blank" rel="noreferrer">
                                <img  className="nav-link download" src={gplayDownload} alt="Gplay" style={{width: 150}}/>
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="btn btn-primary" href="/">Subscribe</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>

    )
}
