import React, { useEffect, useRef } from "react"
import { PAYU_FURL, PAYU_SURL } from "../../../api/apis"

const Form = ({order}) => {
    const formRef = useRef(null)
    useEffect(() => {
        setTimeout(() => {
            formRef.current.submit()
        }, 200)
    }, [])
    return (
        <form action='https://secure.payu.in/_payment' method='post' ref={formRef}>
            <input type="hidden" name="key" value="AA18ZX" />
            <input type="hidden" name="txnid" value={order.order} />
            <input type="hidden" name="productinfo" value={order.item_name} />
            <input type="hidden" name="amount" value={Math.round(order.amount/100).toFixed(2)} />
            <input type="hidden" name="email" value={order.email} />
            <input type="hidden" name="firstname" value={order.name} />
            <input type="hidden" name="lastname" value={''} />
            <input type="hidden" name="surl" value={PAYU_SURL} />
            <input type="hidden" name="furl" value={PAYU_FURL} />
            <input type="hidden" name="phone" value={order.phone_number} />
            <input type="hidden" name="hash" value={order.hash} />
            <input type="hidden" name="udf1" value={order.udf1} />
            <input type="submit" value="submit"/> 
        </form>
    )
}

export default Form