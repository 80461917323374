import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './views/Home/Home';
import MovieLanding from './views/Home/MovieLanding';
import MovieLandingFwila from './views/Home/MovieLandingFwila';
import Subscription from './views/Home/Subscription'
import Footer from './layout/Footer';
// import Privacy from './views/pages/stateless/Privacy';
// import Terms from './views/pages/stateless/Terms';
import Navbar from './layout/Navbar';
import Single from './views/Single/Single';
import Auth from './views/Auth/Auth';
import SignIn from './views/Auth/Signin';
import Account from './views/Auth/Account';
import { AuthProvider } from './context/AuthContext';
import List from './views/List/List'
import Privacy from '../src/stateless/Privacy'
import Terms from '../src/stateless/Terms'
import Contact from '../src/stateless/Contact'
import Refund from './stateless/Refund';
import ScrollToTop from './ScrollTop';
import Publish from './stateless/Publish';
import StripePayment from './views/Payment/StripePayment';
import PayuPage from './views/Payment/PayU';
import Infotainment from './stateless/Infotainment'
import About from './stateless/About'
import 'animate.css';
import Deeplink from './components/Deeplink';

function App() {

  return (
      <Router>
        <AuthProvider>
          <Navbar />
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/about-app" render={() => <About mobile={true} />} />
            <Route exact path="/:type(movies|music-videos|web-series|tv-and-shows)" render={(props) => <List key={props.match.params.type}/>} />
            <Route exact path="/:type(movies|music-videos|web-series|tv-and-shows)/:itemId" render={(props) => <Single key={props.match.params.itemId}/>} />
            <Route exact path="/web-series/:itemId" component={Single} />
            <Route exact path="/auth" component={Auth} />
            {/*<Route exact path="/fwila-mcla" render={() => <MovieLandingFwila image={'https://s3.eu-central-1.wasabisys.com/newbo/Movies_Api/FMCLATrailer23/FWILA WEB POSTER.png'} id={''} name={'Fwiला MCLA'}/>} />*/}
            <Route exact path="/deeplink" component={Deeplink} />

            <Route exact path="/terms&conditions" component={Terms} />
            <Route exact path="/terms-and-conditions.html" component={Terms} />
            <Route exact path="/terms-and-conditions-app" render={ () => <Terms  mobile={true}/>} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/privacy-policy.html" component={Privacy} />
            <Route exact path="/privacy-policy-app" render={() =><Privacy mobile={true} />}  />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/contact.html" element={<Contact mobile={true} />} />
            <Route exact path="/contact-app" render={() =><Contact mobile={true} />}  />
            <Route exact path="/refund-policy" component={Refund} />
            <Route exact path="/refund-policy.html" component={Refund} />
            <Route exact path="/refund-policy-app" render={() =><Refund mobile={true} />}  />
            <Route exact path="/publish" component={Publish} />
            <Route exact path="/payment" component={StripePayment} />
            <Route exact path="/subscription" component={Subscription} />
            <Route exact path="/payment-payu/:id/:type/:name" component={PayuPage} />
            <Route path="/infotainment" component={Infotainment}/>
            <Route path="/infotainment-app" render={() =><Infotainment mobile={true} />}  />
          </Switch>
          <Footer />
        </AuthProvider>
      </Router>
  );
}

export default App;
