import React, { useEffect, useState } from 'react'
import './subscription.scss'
import { UseAuth } from './../../context/AuthContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createOrder, finalizeOrder } from '../../api/apis';
import { FetchSubscription } from '../../models/SubscriptionPlans';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';


const classNames = ['card-basic', 'card-standard', 'card-premium']


export default function Subscription() {
    const {user} = UseAuth()
    const [data, setData] = useState([])
    const [order, setOrder] = useState(null)
    const history = useHistory()
    const [selecteditem, setSelectedItem] = useState(null)

    const subscribe = (item) => {
        if(!user) return history.push('/signin')
        setSelectedItem(item)
        // return history.push('/payment?pi=2')
        return history.push(`/payment-payu/${item.id}/subscription/${item.name}`)
        Swal.fire({
            title: 'Initiating order please wait....',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: () => {
                return createOrder({
                        userID: user.uid,
                        itemID: item.id,
                        type: 'subscription',
                        phoneNumber: user.phoneNumber,
                        itemName: item.name
                    })
            }
        })
        .then(async (resp) => {
            if(resp.value && resp.value.status==200){
                let mOrder = await resp.value.json()
                console.log(mOrder)
                setOrder(mOrder)
                history.push(`/payment?pi=${mOrder.paymentIntent}`)
                // initRazorpay(mOrder)
            }
            console.log(resp)
        })
        .catch(error => {
            console.log(error)
            Swal.fire({
                title: 'Payment Failed',
                icon: 'error',
                text: error.message
            })
        })
        Swal.clickConfirm()
        // toast.dark("Coming soon! Stay Tuned.")
    };

    // const initRazorpay = (order) => {
    //     let options = createOption(order)
    //     var rzp1 = new window.Razorpay(options);
    //     rzp1.on('payment.failed', function (response){
    //         // alert(response.error.code);
    //         // alert(response.error.description);
    //         // alert(response.error.source);
    //         // alert(response.error.step);
    //         // alert(response.error.reason);
    //         // alert(response.error.metadata.order_id);
    //         // alert(response.error.metadata.payment_id);
    //         Swal.fire({
    //             title: 'Payment Failed',
    //             icon: 'error',
    //             text: response.error.description
    //         })
    //     });
    //     rzp1.open();
    // }

    const completeOrder = (razorpay_payment_id, receipt, razorpay_signature) => {
        Swal.fire({
            title: 'Completing order please wait....',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: () => {
                return finalizeOrder({
                        razorpay_payment_id,
                        receipt,
                        razorpay_signature
                    })
            }
        })
        .then(async (resp) => {
            if(resp.value && resp.value.status==200){
                Swal.fire({
                    title: 'Subscription Successful',
                    icon: 'success',
                    text: 'Thanks for Subscription!',
                    confirmButtonText: 'Close'
                })
            }
            console.log(resp)
        })
        .catch(error => {
            console.log(error)
            Swal.fire({
                title: 'Subscription Failed',
                icon: 'error',
                text: 'Please try again!'
            })
        })
        Swal.clickConfirm()
    }

    

    useEffect(() => {
        FetchSubscription({})
        .then(snap => {
            setData(snap.docs.map((v) => { 
                return {
                    ...v.data(), 
                    visible_price: (parseInt(v.data().actual_price)/100).toFixed(2),
                    svisible_price: (parseInt(v.data().offer_price)/100).toFixed(2)
                }
            }))
        })
    }, [])

    
    function createOption(order) {
        var options = {
            "key": "rzp_test_HgyK60ac11YxIc", // Enter the Key ID generated from the Dashboard
            "amount": order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "NEWBO",
            "description": selecteditem.name,
            "image": window.location.origin+"/logo128.png",
            "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response){
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
                completeOrder(response.razorpay_payment_id, order.receipt , response.razorpay_signature)
            },
            "prefill": {
                "name": user.displayName,
                "contact": user.phone
            },
            "notes": order.notes,
            "theme": {
                "color": "#3399cc"
            }
        };
        return options
    }

    const renderPlans = () => {
        return (<div className="card-holder">
                            {data.map((mm, mi) => (
                                <div className={`card shadow-sm ${classNames[mi]}`} key={mi}>
                                    <h4 >{mm.name} </h4>
                                    <h1>₹ {mm.svisible_price} </h1>
                                    <h5 className="text-gray"><s>₹ {mm.visible_price}</s></h5>
                                    <br />
                                    <div>
                                        <button onClick={() => subscribe(mm)} className="btn btn-primary">Buy Now</button>
                                    </div>
                                </div>
                            ))}
                        </div>)
    }

    return (
        <section className="subscription" id="subscription">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1 className="text-center price-title">Pricing Plan</h1>
                        <div className="lil-details">
                            <h5><span>✔</span>Ad-free watch all you want. </h5>
                            <h5><span>✔</span>Recomended just for you</h5>
                            <h5><span>✔</span>Cancel your plan anytime</h5>
                        </div>

                        {!user?<p className="text-center mb-2">Please <span style={{  margin: '0', fontWeight: 'bold' }}> login</span> before buying the subscription</p>:<></>}
                        {!user || !user.active?renderPlans():<div className="text-center card" style={{maxWidth: 500, background: '#27334c', margin: '0 auto'}}>
                        <div className="card-body ">
                            <h3>Active Subscription - {user.subscription.name}</h3>
                            <p>
                                Expires at - {new Date(user.subscription.expires_at).toLocaleDateString()} <br />
                                Purchased at - {new Date(user.subscription.created_at).toLocaleDateString()}
                            </p>
                        </div>
                        </div>}
                        
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>
    )
}




// var rzp1 = new window.Razorpay(options);
// rzp1.on('payment.failed', function (response){
//         alert(response.error.code);
//         alert(response.error.description);
//         alert(response.error.source);
//         alert(response.error.step);
//         alert(response.error.reason);
//         alert(response.error.metadata.order_id);
//         alert(response.error.metadata.payment_id);
// });
// document.getElementById('rzp-button1').onclick = function(e){
//     rzp1.open();
//     e.preventDefault();
// }