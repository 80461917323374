import React from 'react'
import './register.scss'

export default function Register() {
    return (
        <section className="register">
            <form action="">
                <h1>REGISTER</h1>
                <div className="grid">
                    <div className="item">
                        <label htmlFor="">Username</label>
                        <input type="text" />
                    </div>
                    <div className="item">
                        <label htmlFor="">Email</label>
                        <input type="email" />
                    </div>
                    <div className="item">
                        <label htmlFor="">First Name</label>
                        <input type="text" />
                    </div>
                    <div className="item">
                        <label htmlFor="">Last Name</label>
                        <input type="text" />
                    </div>
                    <div className="item">
                        <label htmlFor="">Password</label>
                        <input type="password" />
                    </div>
                    <div className="item">
                        <label htmlFor="">Repeat Password</label>
                        <input type="password" />
                    </div>
                    <div>
                        <input type="radio" />
                        <label htmlFor="">Basic $49 / month</label>
                    </div>
                    <div>
                        <input type="radio" />
                        <label htmlFor="">Basic $49 / month</label>
                    </div>
                    <div>
                        <input type="radio" />
                        <label htmlFor="">Basic $49 / month</label>
                    </div>
                </div>
                <br />
                <a href="#!" className="btn btn-primary btn-lg">Register</a>
            </form>
        </section>
    )
}
