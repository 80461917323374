export const BASE_API =  "https://newatch-fun.azurewebsites.net" // "http://localhost:7071" //   "http://172.17.102.1:7071" // "https://ottfunctions.azurewebsites.net" //"http://192.168.43.128:7071" //
export const RAZORPAY_REATE_ORDER =`${BASE_API}/api/RazorPayCreateOrder`
export const RAZORPAY_ORDER_CALLBACK =`${BASE_API}/api/RazorPayOrderCallback`
export const DRM_LICENSE_ISSUE_TOKEN =`${BASE_API}/api/IssueDRMToken`
export const DRM_LICENSE_VALIDAOR_WIDEVINE ="https://test17.keydelivery.centralindia.media.azure.net/Widevine/"
export const DRM_LICENSE_VALIDAOR_PLAYREADY ="https://test17.keydelivery.centralindia.media.azure.net/PlayReady/"
export const SUBMIT_RATE_REVIEW =`${BASE_API}/api/SubmitRateReview`
// export const CHECK_SUBSCRIPTION =`${BASE_API}/api/CheckSubscription`

export const STRYPE_PAYMENT_SHEET = "https://us-central1-newatch-6c7c7.cloudfunctions.net/paymentSheet" 
export const CHECK_SUBSCRIPTION =`https://us-central1-newatch-6c7c7.cloudfunctions.net/checkSubscription`
export const CREATE_PAYMENT_ORDER = `https://us-central1-newatch-6c7c7.cloudfunctions.net/createPayUOrderWeb`
export const PAYU_SURL = `https://us-central1-newatch-6c7c7.cloudfunctions.net/getPaySurl?ru=https://${window.location.host}`
export const PAYU_FURL = `https://us-central1-newatch-6c7c7.cloudfunctions.net/getPayFurl?ru=https://${window.location.host}`
// export const PAYU_FURL = `http://localhost:5001/newatch-6c7c7/us-central1/getPayFurl?ru=https://${window.location.host}`

export const createOrder = (props) => {
    // const payload = {
    //     userId: props.userID,
    //     itemId: props.itemID,
    //     type: props.type,
    // }
    const payload = {
        user_id: props.userID,
        item_id: props.itemID ,
        item_type   : props.type ,
        phone_number   : props.phoneNumber ,
        item_name   : props.itemName 
    }
    // return payload
    // if(customer_id.isNullOrEmpty().not()) data.put("customer_id", customer_id!!)
    // payload.user_id = props.userID
    // payload.item_id = props.itemID 
    // payload.type    = props.type 
    // payload.item_type    = props.itemType 
    // payload.phone_number    = props.phoneNumber 
    // payload.item_name    = props.itemName 
    
    return fetch(STRYPE_PAYMENT_SHEET, {
                method: "post",
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
}

export const finalizeOrder = ({razorpay_payment_id, receipt, razorpay_signature}) => {
    const payload = { razorpay_payment_id, receipt, razorpay_signature }

    return fetch(RAZORPAY_ORDER_CALLBACK, {
                method: "post",
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
}

export const CheckSubscription = (idToken) => {
    return fetch(CHECK_SUBSCRIPTION, {
        method: "post",
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({idToken})
    })
}

export const createPayuOrder = (props) => {
    const payload = {
        user_id: props.user_id,
        item_id: props.item_id ,
        item_type   : props.item_type ,
        phone_number   : props.phone_number,
        email: props.email,
        name: props.name,
        item_name   : props.item_name, 
        udf1   : props.udf1, 
        udf2   : props.udf2, 
        udf3   : props.udf3, 
        udf4   : props.udf4, 
        udf5   : props.udf5
    }
    
    return fetch(CREATE_PAYMENT_ORDER, {
                method: "post",
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
}