import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Redirect } from 'react-router';
import {auth} from './../../initFirebase'
import './signin.scss'

// Configure FirebaseUI.
const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: 'http://localhost:3000/account',
    signInOptions: [
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            defaultCountry: 'IN'
        },
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        signInSuccessWithAuthResult: () => true,
    },
};

function SignIn() {
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver();
    }, []);

    if (!isSignedIn) {
        return (
            <div className="container signin">
                <h1 className="text-center mt-5 mb-4">Sign In / Register</h1>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            </div>
        );
    }

    return <Redirect to="/account" />

    // return (
    //     <div className="container">
    //         {console.log('user')}
    //         {console.log(auth.currentUser)}
    //         <h1 className="mt-5 text-white">Signed in as {auth.currentUser?auth.currentUser.displayName:''}</h1>
    //         <a href="/account" className="btn btn-primary">Account</a>
    //     </div>
    // )
}

export default SignIn;