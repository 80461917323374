import React from "react"

export default function Refund({mobile = false}) {
    return (
        <div className="text-whiter p-4" style={{minHeight: 600}}>
            {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
            <div className="col-12">
            <h1 className="text-center mb-4">REFUND POLICY</h1>
            </div>
            <div className="col-md-8 offset-md-2">
            
            <h4>Refunds (if applicable)</h4>
            
            <p>
                Once you send us mail for return at newboindia@gmail.com with details of phone number associated with your account, we will review the refund eligibility. The refund eligibility requires that you have not watched any premium shows from subscription or purchased item.
            <br/>
            If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
            </p>
          
          
          <h4>Late or missing refunds (if applicable)</h4>
          
          <p>
            If you haven’t received a refund yet, first check your bank account again.
          <br/>
          Then contact your credit card company, it may take some time before your refund is officially posted.
          <br/>
          Next contact your bank. There is often some processing time before a refund is posted.
          <br/>
          If you’ve done all of this and you still have not received your refund yet, please contact us at newboindia@gmail.com.
          </p>
          
          
          <h4>Sale items (if applicable)</h4>
          
          Only regular priced items may be refunded, unfortunately sale items cannot be refunded.
          
        </div>
        </div>
    )
}