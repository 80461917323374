import React from 'react'
import { Link } from 'react-router-dom'
import gplayDownload from './../google-play-badge.png'
import iosDownload from './../downloadios.png'
import Swal from 'sweetalert2'
import logo from './../assets/NewboWebsite.png'

export default function Footer() {
    const year = new Date().getFullYear()
    return (
        <footer className='mt-2'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 footer-brand">
                        {/*<a href="/" className="brand ">NEWBO</a>*/}
                        <a href="/" className="brand "><img src={logo} style={{maxWidth: 160}}/></a>
                        <p style={{ fontSize: '0.9rem', width: '90%', marginTop: '1rem' }}>NEWBO is an an on-demand video streaming platform for movies, web series contents made and originating
                        from North Eastern regions of India.
                        We are trying to create an unified entertainment platform
                        for people in the region.</p>
                    </div>
                    {/*<div className="col-lg-2">*/}
                        {/*<h3 className="footer-border-bottom">Explore</h3>
                        <Link to="/movies" className="nav-link">Movies</Link>
                        <Link to="/web-series" className="nav-link">Web Series</Link>
                        <Link to="/tv-and-shows" className="nav-link">Tv & Shows</Link>
                        <a href="https://forms.gle/BLTQos1oSbcBrSVU7" target={"_blank"} className="nav-link">Careers</a>
                        <Link to="/publish" className="nav-link" style={{fontWeight: 'bold', textTransform: 'uppercase'}}><u>Looking to Publish Content?</u></Link>*/}
                    {/*</div>*/}
                    <div className="col-lg-4">
                        <h3 className="footer-border-bottom">More</h3>
                        {/* <Link to="/about-us" className="nav-link">About Us</Link> */}
                        <Link to="/contact" className="nav-link">Connect with Us</Link>
                        <Link to="/privacy-policy" className="nav-link">Privacy Policy</Link>
                        <Link to="/refund-policy" className="nav-link">Refund Policy</Link>
                        <Link to="/terms&conditions" className="nav-link">Terms & Conditions</Link>
                        <Link to="/publish" className="nav-link" style={{fontWeight: 'bold', textTransform: 'uppercase'}}><u>Looking to Publish Content?</u></Link>
                    </div>
                    <div className="col-lg-4">
                        <h3>Download The App</h3>
                        <a href="https://play.google.com/store/apps/details?id=com.mars.newatch" target="_blank" rel="noreferrer">
                            <img  className="nav-link download" src={gplayDownload} alt="Gplay" />
                        </a>
                        <a  target="_blank" rel="noreferrer" onClick={() => {
                            Swal.fire({title: 'Coming soon.', confirmButtonText: 'Close', icon: 'info'})
                        }}>
                            <img  className="nav-link download" src={iosDownload} alt="IOS" style={{ padding: 20, marginLeft: -10 }}/>
                        </a>
                    </div>
                </div>
                <div className="row p-0 mb-0 text-center">
                    <div className="col footer-end">
                        <p className="my-2"> &copy; {year} Swrang Technology Private Limited.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
