import React, { useEffect, useState } from 'react'
import Subscription from './Subscription'
import HomeCarousel from './HomeCarousel'
import BigCarousel from './BigCarousel'
import Swal from 'sweetalert2'
import gplayDownload from './../../google-play-badge.png'
import { Link } from 'react-router-dom'
import HomePhone from '../../components/HomePhone'

export default function Home(props) {
    const [params, setParams] = useState(null)
    useEffect(() => {
        // setParams(new URLSearchParams(props.location.search))
        let tmp = {}, search = props.location.search || ''
        if(search.charAt(0)==='?') search = search.substring(1)
        search.split('&').map(v => v.trim()).filter(v => v.length).forEach(element => {
            tmp[element.split('=')[0]] = element.split('=')[1]
        });
        setParams(tmp)
    }, [])

    useEffect(() => {
        if(params && params.msg){
            Swal.fire({
                title: decodeURIComponent(params.msg),
                html: params.type=='success'?'<small>The subscription might show a little delay.</small>':'',
                icon: params.type || 'info',
                confirmButtonText: 'CLOSE'
            })
        }
    }, [params])

    return (
        <>
            {/*<BigCarousel  />*/}
            <HomePhone />
            <div className='alert alert-info text-center'>Currently movies are streaming on android app! <b>
                {/* <a href="https://cdn62706266.blazingcdn.net/newbo/app-release.apk" target="_blank">Download android app here.</a> */}
                <a href="https://play.google.com/store/apps/details?id=com.mars.newatch" target="_blank" rel="noreferrer">
                            <img style={{margin: '0 auto', maxHeight: 100}} className="nav-link download" src={gplayDownload} alt="Gplay" />
                        </a>
                </b> 
            </div>
            <div className='text-center pb-4 pt-4'>
                <Link to={"/publish"} style={{color: 'blue'}}><u>Are you a publisher or content creator looking to publish?</u></Link>
            </div>
            {/* <HomeCarousel title="Web Series"  type="web-series" key="web-series"/> */}
            {/*<HomeCarousel title="Movies" type="movies" key="movies"/>*/}
            {/* <HomeCarousel title="Music Videos" type="music-videos" key="music-videos"/> */}
            {/* <Subscription /> */}
            {/* <DummyDiv /> */}
        </>
    )
}
